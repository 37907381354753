<template>
  <main>
    <div v-if="investment && offering">
      <div v-if="investment.status != 'draft'">
        <div class="form-outline mb-4 container-fluid d-flex justify-content-center">
          Your investment, <b>{{ investment.investmentName }}</b>, has already been submitted and can no longer be edited.
        </div>
        <div class=" form-outline mb-4 container-fluid">
          <div class="row text-center p-5">
            <div class="col-md-6">
              <router-link :to="{ name: 'Investments', params: { id: offering.id } }" class="btn btn-primary">
                View submitted investments
              </router-link>
            </div>
            <div class="col-md-6">
              <router-link :to="{
                name: 'OfferingInvest',
                params: {
                  id: offering.id
                }
              }" class="btn btn-primary" style="position: absolute, left: 0">create a new investment</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h6>Investment Review</h6>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="name">Investment Name</div>
              <div class="serious">{{ investment.investmentName }}</div>
            </div>
            <div class="col-md-3">
              <div class="name">Investment Type</div>
              <div v-if="investment.investmentType == 'joint'" class="serious">
                Joint Investment
              </div>
              <div v-if="investment.investmentType == 'individual'" class="serious">
                Individual Investment
              </div>
              <div v-if="investment.investmentType == 'entity'" class="serious">
                Entity Investment
              </div>
            </div>
            <div class="col-md-4">
              <div v-if="investment.primaryAccreditedInvestor == 'yes'">
                <div class="name">Investor Type</div>
                <div v-if="investment.investmentType == 'joint'" class="serious">
                  Accredited Investors
                </div>
                <div v-if="investment.investmentType == 'individual'" class="serious">
                  Accredited Investor
                </div>
                <div v-if="investment.investmentType == 'entity'" class="serious">
                  Accredited Entity
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="investment.investmentType == 'entity'">
          <h6>Entity</h6>

          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="col-md-3">
                <div class="name">Entity Name</div>
                <div class="serious">
                  {{ investment.entityName }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="name">Entity Start Date</div>
                <div class="serious">
                  {{ toPrettyDate(investment.entityStartDate) }}
                </div>
              </div>

              <div class="col-md-3">
                <div class="name">Entity Type</div>
                <div class="serious">{{ investment.entityType }}</div>
              </div>
              <div class="col-md-3">
                <div class="name">Entity Industry</div>
                <div class="serious">{{ investment.entityIndustry }}</div>
              </div>
            </div>
          </div>
          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div v-if="investment.isFinCenRegulated" class="serious">
                  This entity is FinCen Regulated
                </div>
                <div v-else class="serious">
                  This entity not FinCen Regulated
                </div>
              </div>
              <div class="col-md-6">
                <div v-if="investment.isUSEntity">
                  <div class="serious">This is a US Entity</div>
                  <span class="name"> TIN/EIN: </span><span class="serious">{{ investment.entityTaxId }}</span>
                </div>
                <div v-else>
                  <div v-if="investment.isEntityFtin">
                    <span class="name">FTIN: </span>
                    <span class="serious">{{ investment.entityTaxId }}</span>
                  </div>
                  <div v-else class="serious">
                    This entity does not have an FTIN
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-7">
                <div class="name">Entity Address</div>
                <div class="serious">
                  <div>
                    {{ investment.entityStreetAddress1 }}
                  </div>
                  <div v-if="investment.entityStreetAddress2">
                    {{ investment.entityStreetAddress2 }}
                  </div>
                  <div>
                    {{ investment.entityCity }},
                    {{ investment.entityState }}
                    {{ investment.entityPostalCode }}
                    {{ investment.entityCountry }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6>Purchaser Representative / Owner Signatory</h6>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="serious">
                  {{ investment.entityRepresentativeName }},
                  {{ investment.entityRepresentativeTitle }}
                </div>
                <div class="serious">
                  {{ investment.entityRepresentativePhoneNumber }}
                </div>
                <div class="serious">
                  {{ investment.entityRepresentativeEmail }}
                </div>
              </div>
              <div class="col-md-6">
                <div>{{ investment.entityRepresentativeStreetAddress1 }}</div>
                <div v-if="investment.entityRepresentativeStreetAddress2">
                  {{ investment.entityRepresentativeStreetAddress2 }}
                </div>
                <div>
                  {{ investment.entityRepresentativeCity }}
                  {{ investment.entityRepresentativeState }}
                  {{ investment.entityRepresentativePostalCode }}
                  {{ investment.entityRepresentativeCountry }}
                </div>
              </div>
            </div>
          </div>
          <h6>Owners (> 10% ownership)</h6>
          <div class="form-outline mb-4 container-fluid">
            <div v-if="investment.isEntityOwnersAccredited" class="serious mb-2">
              All Owners are Accredited
            </div>
            <div class="row mb-2" v-for="(owner, index) in investment.owners" :key="index">
              <div class="serious fw-bold mb-2">Owner #{{ index + 1 }}</div>
              <div class="row">
                <div class="col-md-6">
                  <div class="serious">{{ owner.entityOwnerName }}</div>
                  <div class="serious">{{ owner.entityOwnerDateOfBirth }}</div>
                  <div class="serious">{{ owner.entityOwnerSSN }}</div>
                  <div class="serious">{{ owner.entityOwnerEmail }}</div>
                </div>
                <div class="col-md-6">
                  <div>{{ owner.entityOwnerStreetAddress1 }}</div>
                  <div v-if="owner.entityOwnerStreetAddress2">
                    {{ owner.entityOwnerStreetAddress2 }}
                  </div>
                  <div>
                    {{ owner.entityOwnerCity }}
                    {{ owner.entityOwnerState }}
                    {{ owner.entityOwnerPostalCode }}
                    {{ owner.entityOwnerCountry }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h6 v-if="investment.investmentType == 'joint'">Primary Investor</h6>
          <h6 v-else>Investor</h6>
          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="name">Name</div>
                <div class="serious">
                  {{ investment.primaryFullName }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="name">Date of Birth</div>
                <div class="serious">
                  {{ toPrettyDate(investment.primaryDateOfBirth) }}
                </div>
              </div>

              <div class="col-md-4">
                <div class="name">Email</div>
                <div class="serious">{{ investment.primaryEmail }}</div>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-7">
                <div class="name">Address</div>
                <div class="serious">
                  <div>
                    {{ investment.primaryAddressLine1 }}
                  </div>
                  <div v-if="investment.primaryAddressLine2">
                    {{ investment.primaryAddressLine2 }}
                  </div>
                  <div>
                    {{ investment.primaryCity }},
                    {{ investment.primaryState }}
                    {{ investment.primaryPostalCode }}
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="name">Phone Number</div>
                <div class="serious">
                  {{ formatPhoneNumber(investment.primaryPhoneNumber) }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="investment.investmentType == 'joint'">
            <h6>Secondary Investor</h6>
            <div class="form-outline mb-2 container-fluid">
              <div class="row">
                <div class="col-md-4">
                  <div class="name">Name</div>
                  <div class="serious">
                    {{ investment.secondaryFullName }}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="name">Date of Birth</div>
                  <div class="serious">
                    {{ toPrettyDate(investment.secondaryDateOfBirth) }}
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="name">Email</div>
                  <div class="serious">{{ investment.secondaryEmail }}</div>
                </div>
              </div>
            </div>
            <div class="form-outline mb-4 container-fluid">
              <div class="row">
                <div class="col-md-7">
                  <div class="name">Address</div>
                  <div class="serious">
                    <div>
                      {{ investment.secondaryAddressLine1 }}
                    </div>
                    <div v-if="investment.secondaryAddressLine2">
                      {{ investment.secondaryAddressLine2 }}
                    </div>
                    <div>
                      {{ investment.secondaryCity }},
                      {{ investment.secondaryState }}
                      {{ investment.secondaryPostalCode }}
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="name">Phone Number</div>
                  <div class="serious">
                    {{ formatPhoneNumber(investment.secondaryPhoneNumber) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6>Investment Details</h6>
        <div class="form-outline mb-4 container-fluid">
          <div v-if="investment.primaryAccreditedInvestor == 'no'" class="row">
            <div class="col-md-4">
              <div v-if="investment.investmentType == 'individual'" class="name">
                Annual Income
              </div>
              <div v-if="investment.investmentType == 'joint'" class="name">
                Joint Annual Income
              </div>
              <div v-if="investment.investmentType == 'entity'" class="name">
                Entity Annual Income
              </div>
              <div class="serious">
                ${{ numberWithCommas(investment.primaryAnnualIncome) }}
              </div>
            </div>
            <div class="col-md-4">
              <div v-if="investment.investmentType == 'entity'" class="name">
                Entity Net Worth
              </div>
              <div v-else class="name">Household Net Worth</div>
              <div class="serious">
                ${{ numberWithCommas(investment.primaryNetWorth) }}
              </div>
            </div>
            <div v-if="investment.primaryOtherInvestments == 'yes'" class="col-md-4">
              ${{ investment.primaryOtherInvestmentTotal }}
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="name">Total Investment</div>
              <div class="serious">
                ${{ numberWithCommas(investment.totalInvestment) }}
              </div>
            </div>
            <div class="col-md-4">
              <div class="name">Total Shares</div>
              <div class="serious">
                {{ numberWithCommas(investment.totalShares) }}
              </div>
            </div>
            <div class="col-md-4">
              <div class="name">Payment Method</div>
              <div class="serious">{{ investment.paymentMethod }}</div>
            </div>
          </div>
        </div>
        <div v-if="investment.zsIsDocumentSigned">
          <div>The agreement was signed {{ investment.zsSignatureTime }}</div>
          <div>
            Please wait until we complete your investment.
          </div>
          <div>Investing now...</div>
          <button v-if="isPending || isInvesting" class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Submitting agreement...</span>
            </div>
          </button>
        </div>
        <div class="col-md-6">
          <span v-if="!isInvesting" class="btn btn-primary" @click="showOfferingAgreement">
            Invest Now
          </span>
          <button v-else class="btn btn-primary" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            loading your offering document...
          </button>
        </div>
        <!-- Test Button End -->
        <div v-if="errorOffering" class="error">{{ errorOffering }}</div>
        <div v-if="errorSignature" class="error">{{ errorSignature }}</div>
        <div v-if="errorInvestment" class="error">{{ errorInvestment }}</div>
        <div v-if="errorUpdate" class="error">{{ errorUpdate }}</div>
        <div v-if="errorInvesting" class="error">{{ errorInvesting }}</div>
        <div class="row text-center p-5">
          <div class="col-md-4">
            <button v-if="isPending || isInvesting" class="btn btn-primary" disabled>
              <i class="bi bi-arrow-left"></i>Prev
            </button>
            <router-link v-else :to="{
              name: 'InvestmentDetails',
              params: { id: offering.id, draftId: investment.id }
            }" class="btn btn-secondary">
              <i class="bi bi-arrow-left"></i>Prev
            </router-link>
          </div>
          <div class="col-md-4">
            <button v-if="isPending || isInvesting" class="btn btn-primary" disabled>
              Cancel
            </button>
            <router-link v-else :to="{
              name: 'OfferingInvestments',
              params: { id: offering.id }
            }" class="btn btn-secondary">
              Cancel
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="investment" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { projectFunctions } from "@/firebase/config";
import { ref } from "@vue/runtime-core";
import usePrettyNumbers from "@/composables/usePrettyNumbers";
import { timestamp } from "../../../firebase/config";
import { useRouter } from "vue-router";

export default {
  props: ["id", "draftId"],
  components: { Footer },
  setup(props) {
    //Temporary Zoho Sign Values
    const embeddedURL = ref("https://www.bitfog.com/");
    const actionIdDocument = ref("");
    const actionIdTemplate = ref("");
    const requestId = ref("");
    const authToken = ref("");
    const templateId = ref("");
    //End of Zoho Sign Values
    const isPending = ref(false);
    const isInvesting = ref(false);
    const errorSignature = ref(null);
    const errorInvesting = ref(null);
    const router = useRouter();

    const {
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate
    } = usePrettyNumbers();
    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );
    const { error: errorInvestment, document: investment } = getDocument(
      "investmentDrafts",
      props.draftId
    );
    const { error: errorUpdate, updateDoc: updateInvestment } = useDocument(
      "investmentDrafts",
      props.draftId
    );

    const zsShowDocument = projectFunctions.httpsCallable(
      "zsShowDocument"
    );

    const zsCreateDocument = projectFunctions.httpsCallable("zsCreateDocument");
    const makeInvestment = projectFunctions.httpsCallable("makeInvestment");

    const showOfferingAgreement = async () => {
      try {
        isInvesting.value = true;
        errorInvesting.value = false;
        let investorName = "";
        let taxId = "";
        let title = " ";
        let streetAddress = "";
        let cityStateZip = "";
        let phoneNumber = "";
        let email = "";
        if (investment.value.investmentType == "entity") {
          taxId =
            investment.value.entityTaxId.length > 4
              ? investment.value.entityTaxId.slice(-4)
              : investment.value.entityTaxId;
          taxId = taxId.padStart(investment.value.entityTaxId.length, "*");
          investorName = investment.value.entityRepresentativeName;
          title = investment.value.entityRepresentativeTitle ? investment.value.entityRepresentativeTitle : " ";
          streetAddress = `${investment.value.entityRepresentativeStreetAddress1} ${investment.value.entityRepresentativeStreetAddress2}`;
          cityStateZip = `${investment.value.entityRepresentativeCity}, ${investment.value.entityRepresentativeState} ${investment.value.entityRepresentativePostalCode}`;
          phoneNumber = investment.value.entityRepresentativePhoneNumber;
          email = investment.value.entityRepresentativeEmail;
        } else {
          taxId =
            investment.value.primarySSN.length > 4
              ? investment.value.primarySSN.slice(-4)
              : investment.value.primarySSN;
          taxId = taxId.padStart(investment.value.primarySSN.length, "*");
          investorName =
            investment.value.primaryFirstName +
            " " +
            investment.value.primaryMiddleName +
            " " +
            investment.value.primaryLastName;
          streetAddress = `${investment.value.primaryAddressLine1} ${investment.value.primaryAddressLine2}`;
          cityStateZip = `${investment.value.primaryCity}, ${investment.value.primaryState} ${investment.value.primaryPostalCode}`;
          phoneNumber = investment.value.primaryPhoneNumber;
          email = investment.value.primaryEmail;
        }
        console.log(`STREET ADDRESS: ${streetAddress}`);
        console.log(`Show Offering Agreement RequestId: ${investment.value.zsDocumentRequestId} ActionId: ${investment.value.zsDocumentActionId}`);
        const resShowDocument = await zsShowDocument({
          zsDocumentRequestId: investment.value.zsDocumentRequestId,
          zsDocumentActionId: investment.value.zsDocumentActionId,
          templateActionId: offering.value.zsTemplateActionId,
          templateId: offering.value.zsTemplateId,
          offeringId: offering.value.id,
          offeringName: offering.value.name,
          investmentAmount: `$${numberWithCommas(
            investment.value.totalInvestment
          )}`,
          investorName: investorName,
          investorTitle: title,
          taxId: taxId,
          streetAddress: streetAddress,
          cityStateZip: cityStateZip,
          phoneNumber: phoneNumber,
          emailAddress: email,
          fullAddress: `${streetAddress}, ${cityStateZip}`,
          draftId: props.draftId
        });
        if (resShowDocument.error) {
          errorInvesting.value = `Embed Document Error: ${resShowDocument.error}`;
          isInvesting.value = false;
        } else {
          console.log(`Embedded URL: ${resShowDocument.data.embedded_url}`);
          //TODO Save document_action_id and document_request_id
          if (resShowDocument.data.embedded_url) {
            errorUpdate.value = "";
            await updateInvestment({
              zsDocumentActionId: resShowDocument.data.document_action_id,
              zsDocumentRequestId: resShowDocument.data.document_request_id,
              editedAt: timestamp()
            });
            //TODO embed URL
            if (errorUpdate.value) {
              errorInvesting.value = `Unable to update your account, contact support. (Update Error: ${errorUpdate.value})`;
              isInvesting.value = false;
            } else {
              window.location = resShowDocument.data.embedded_url;
            }
          } else {
            errorInvesting.value = `The offering document is unavailable, contact support.`;
            isInvesting.value = false;
          }
        }
      } catch (err) {
        isInvesting.value = false;
        errorInvesting.value = err.message;
      }
    };

    const handleDocumentSigned = async () => {
      //TODO hide modal here
      console.log("Document Signed!");
    };

    const zsEmbedDocument = async () => {
      try {
        errorInvesting.value = null;
        isPending.value = true;
        let investorName =
          investment.value.primaryFirstName +
          " " +
          investment.value.primaryMiddleName +
          " " +
          investment.value.primaryLastName;
        let email = investment.value.primaryEmail
          ? investment.value.primaryEmail
          : "michael+noemail@roundhere.co";
        const embeddedDocument = await zsCreateDocument({
          investorName: investorName,
          emailAddress: email,
          investmentAmount: `$${numberWithCommas(
            investment.value.totalInvestment
          )}`,
          templateId: templateId.value,
          actionIdTemplate: actionIdTemplate.value,
          requestId: requestId.value,
          authToken: authToken.value
        });
        isPending.value = false;
        console.log(JSON.stringify(embeddedDocument));
        console.log(
          `Status: ${embeddedDocument.data.status}  Data Status: ${embeddedDocument.data.data_status}  Data Message: ${embeddedDocument.data.data_message}`
        );
        if (embeddedDocument.data.requests) {
          //Grab request_id and action_id
          console.log(
            `Request ID: ${embeddedDocument.data.requests.request_id}`
          );
          requestId.value = embeddedDocument.data.requests.request_id;
          console.log(
            `actionIdDocument: ${embeddedDocument.data.requests.actions[0].action_id}`
          );
          actionIdDocument.value =
            embeddedDocument.data.requests.actions[0].action_id;
        } else {
          errorInvesting.value = `Embed Document Error zsCreateDocument: Status: ${embeddedDocument.data.status}  Data Status: ${embeddedDocument.data.data_status}  Data Message: ${embeddedDocument.data.data_message}`;
        }
      } catch (err) {
        console.log(`Caught error in zsEmbedDocument ${err.message}`);
        errorSignature.value = `No offering document found. ${err.message}`;
        isPending.value = false;
      }
    };


    const confirmInvestment = async () => {
      try {
        errorInvesting.value = null;
        isInvesting.value = true;
        isPending.value = true;
        const newInvestment = await makeInvestment({
          investmentDraftId: investment.value.id
        });
        if (newInvestment.error) {
          errorInvesting.value = `Investment Error: ${newInvestment.error}`;
          isPending.value = false;
        } else {
          var cleanOwners = "";
          if (investment.value.owners) {
            cleanOwners = investment.value.owners.map(item => {
              const container = {};
              container.entityOwnerName = item.entityOwnerName;
              container.entityOwnerDateOfBirth = item.entityOwnerDateOfBirth;
              container.entityOwnerSSN = "*********";
              container.entityOwnerEmail = item.entityOwnerEmail;
              container.entityOwnerStreetAddress1 =
                item.entityOwnerStreetAddress1;
              container.entityOwnerStreetAddress2 =
                item.entityOwnerStreetAddress2;
              container.entityOwnerCity = item.entityOwnerCity;
              container.entityOwnerState = item.entityOwnerState;
              container.entityOwnerPostalCode = item.entityOwnerPostalCode;
              container.entityOwnerCountry = item.entityOwnerCountry;

              return container;
            });
          }
          await updateInvestment({
            completedInvestmentId: newInvestment.data.investmentId,
            completedInvestmentTime: timestamp(),
            primarySSN: "*********",
            secondarySSN: "*********",
            paymentAccountNumber: "*********",
            paymentAccountNumberConfirmed: "",
            paymentAccountRoutingNumber: "*********",
            owners: cleanOwners,
            status: "completed",
            editedAt: timestamp()
          });
          if (errorUpdate.value) {
            errorInvesting.value = `Error: ${errorUpdate.value}`;
            isPending.value = false;
          } else {
            router.push({
              name: "InvestmentConfirmation",
              params: { id: offering.value.id, draftId: props.draftId }
            });
          }
        }
        isInvesting.value = false;
      } catch (err) {
        errorInvesting.value = `Server Error: ${err.message}`;
        isInvesting.value = false;
      }
    };

    return {
      embeddedURL,
      actionIdDocument,
      actionIdTemplate,
      requestId,
      authToken,
      templateId,
      isPending,
      isInvesting,
      errorOffering,
      errorInvestment,
      errorSignature,
      errorInvesting,
      errorUpdate,
      offering,
      investment,
      numberWithCommas,
      formatPhoneNumber,
      toPrettyDate,
      showOfferingAgreement,
      zsEmbedDocument,
      confirmInvestment,
      handleDocumentSigned
    };
  }
};
</script>

<style></style>